<div class="modal-header">
    <h3 class="modal-title" l10n="Agito.Hilti.Profis3.VirtualTour.Title"></h3>

    <button type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <button type="button" class="button button-default"
        (click)="openNavigationTour(false)"
        l10n="Agito.Hilti.Profis3.HomepageWalkthrough">
    </button>

    <button type="button" class="button button-default" *ngIf="isNewHomePageTourAvailable"
        (click)="openNavigationTour(true)"
        l10n="Agito.Hilti.Profis3.NewHomepageWalkthrough">
    </button>

    <button type="button" class="button button-default" *ngFor="let tour of designTours"
        (click)="openDesignTour(tour)">
        {{ getButtonText(tour) }}
    </button>
</div>

<div class="modal-footer">
    <button type="button" class="button button-primary" (click)="close()"
        l10n="Agito.Hilti.Profis3.VirtualTour.Ok">
    </button>
</div>
